.App {
  /* text-align: center; */
  padding: 16px;
  height: calc(100% - 32px);
}


html, body, #root {
  height: 100%;
}


body {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.content {
	width: 400px;
	background: #fff;
	padding: 30px;
	text-align: center;
	z-index: 995;
  box-shadow:0 3px 36px 0 rgba(0, 0, 0, 0.6);
  display:inline-block;
  opacity:0.7;
  color:#666;
  text-decoration:none;
  transition:all 0.2s
}
